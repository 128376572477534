<template>
    <v-card id="boxshadow" elevation="8" class="dash-card m-1 cursor-pointer">
        <a href="#control/application" class="text-decoration-none text-light">
            <div class="top-icon text-center text-primary p-1">
                <v-icon x-large class="pa-4">{{cardData.icon}}</v-icon>
            </div>
            <div class="card-footer fst-normal text-center mt-2 pa-2">
                {{cardData.name}}
                <slot></slot>
            </div>
        </a>
    </v-card>

</template>

<script>

export default {
        name: "hoverCard",
        props: {
            cardData: Object,

        },
        methods: {
   
        }
};
</script>

<style lang="scss" scoped>
    #boxshadow {
        transition: 0.3s ease-in-out;
    }
    .dash-card {
        transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
        transform: scale(0.95);
        &:hover {
            transform: scale(1);
            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
        }
    }
    .text-light {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
    }
    .card-footer {
        color: #000000;
        background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
        border-top: 1px solid #8080808c;
    }
</style>